@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.text {
  word-break: break-word;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.sectionCentered {
  margin-right: auto;
  margin-left: auto;
}

.text-xs {
  font-size: rem-calc(14);
  line-height: var(--line-height, 1.3);
}

.justify-bottom {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.font-family-mono {
  --font: 'Basis-Grotesque-Mono-Pro';
}

.font-family-custom-font {
  --font: var(--custom-font);
}

.hasColorParameter {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: inherit;
  }
}

.hasFontSizeParameter {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-size: inherit;
  }
}

.mobile-u-h5 {
  composes: u-h5 from global;

  > * {
    font-size: inherit;
  }
}

.displayListInline {
  ul {
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
    }
  }
}

.u-h2 {
  composes: u-h2 from global;

  > * {
    font-size: inherit;
  }
}

.u-h2 {
  composes: u-h2 from global;

  > * {
    font-size: inherit;
  }
}

.u-h3 {
  composes: u-h3 from global;

  > * {
    font-size: inherit;
  }
}

.u-h4 {
  composes: u-h4 from global;

  > * {
    font-size: inherit;
  }
}

.u-h5 {
  composes: u-h5 from global;

  > * {
    font-size: inherit;
  }
}

.u-h6 {
  composes: u-h6 from global;

  > * {
    font-size: inherit;
  }
}

.boldStyles {
  b,
  strong {
    font-family: 'Basis-Grotesque-Bold-Pro';
  }
}

.codeInlineStyles {
  code {
    font-family: 'Basis-Grotesque-Mono-Pro';
    background-color: var(--grey-page);
    padding: 0 5px;
  }
}

.mobileFontSize {
  @media screen and (max-width: $tablet-max-breakpoint) {
    font-size: 20px !important;
  }
}

@media screen and (max-width: $mobile-max-breakpoint) {
  .mobile-u-h1 {
    composes: u-h1 from global;

    > * {
      font-size: inherit;
    }
  }

  .mobile-u-h2 {
    composes: u-h2 from global;

    > * {
      font-size: inherit;
    }
  }

  .mobile-u-h3 {
    composes: u-h3 from global;

    > * {
      font-size: inherit;
    }
  }

  .mobile-u-h4 {
    composes: u-h4 from global;

    > * {
      font-size: inherit;
    }
  }

  .mobile-u-h5 {
    composes: u-h5 from global;

    > * {
      font-size: inherit;
    }
  }
}

@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.opentypeFeature,
.opentypeFeatureStyled {
  font-size: var(--font-feature-font-size, 4vw);
  font-family: var(--opentype-font);
  font-feature-settings: var(--opentype-feature-settings);

  &__children,
  &__Inner {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    div {
      font-family: var(--opentype-font);
      font-feature-settings: var(--opentype-feature-settings);
    }
  }

  &:hover {
    font-family: var(--opentype-hover-font, var(--opentype-font));
    font-feature-settings: var(--opentype-feature-settings-hover);

    .opentypeFeature__children,
    .opentypeFeatureStyled__children,
    .opentypeFeature__Inner,
    .opentypeFeatureStyled__Inner {
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      span,
      div {
        font-family: var(--opentype-hover-font, var(--opentype-font));
        font-feature-settings: var(--opentype-feature-settings-hover);
      }
    }
  }
}

.opentypeFeatureStyled {
  margin-bottom: var(--spacing-m);

  &__Inner {
    padding: var(--spacing-base);
    margin-bottom: 5px;
    border: 1px solid var(--grey-mid);
  }
}
